import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();
const height = window.innerHeight;

export default function TransactionModal(props) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState([]);
  const [transactionDeleteData, setTransactionDeleteData] = React.useState([]);

  const [totalProfitLoss, setProfitLoss] = React.useState(0);
  const [totalBrokerage, setTotalBrokerage] = React.useState(0);
  const [totalVolume, setTotalVolume] = React.useState(0);

  React.useEffect(() => {
    load_transactions_history();
  }, []);

  const load_transactions_history = async () => {
    if (!loading) {
      setLoading(true);
      let data = {
        p_id: props.positionId,
      };
      setLoading(true);
      const response = await apiCall("/load_transactions_history", data);
      setLoading(false);

      setTransactionData(response.transactions);
      setTotalBrokerage(parseFloat(response.brokerage).toFixed(2));
      setTotalVolume(parseFloat(response.volume).toFixed(0));
      setProfitLoss(parseFloat(response.profit_loss).toFixed(3));
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div
          style={{
            color: NewTheme.whiteColor,
          }}
        >
          {"Transaction History "}
        </div>
      }
      headerStyle={{ backgroundColor: NewTheme.MainColor }}
      visible={props.visible}
      position={props.position}
      style={{ width: "100vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "absolute",
          top: 20,
          right: 120,
          fontWeight: "bold",
          color: NewTheme.whiteColor,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            Profitloss
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                totalProfitLoss >= 0 ? NewTheme.greencolor : NewTheme.redcolor,

              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 10,
            }}
          >
            <NumberFormatIn value={parseFloat(totalProfitLoss).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12 }}>Brokerage</div>
          <div
            style={{
              fontSize: 16,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 10,
              color: NewTheme.greencolor,
              marginTop: 3,
            }}
          >
            <NumberFormatIn value={totalBrokerage} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12, color: NewTheme.whiteColor }}>Volume</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 10,
              color: NewTheme.blackColor,
            }}
          >
            <NumberFormatIn value={totalVolume} />
          </div>
        </div>
      </div>
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollable
        scrollHeight={height - 120}
        size="small"
        // filters={filters}
        // filterDisplay="row"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        value={!loading && transactionData}
        style={{ fontWeight: "bold", fontSize: 14, marginTop: 20 }}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        globalFilterFields={["username", "symbol"]}
        paginator
        rows={15}
        rowsPerPageOptions={[10, 15, 30, 50, 100]}
      >
        <Column
          field="username"
          showFilterMenu={false}
          header="Client"
          sortable
        />
        <Column
          field="symbol"
          showFilterMenu={false}
          header="Symbols"
          sortable
          filter
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                {rowData.symbol}
              </div>
            </>
          )}
        />
        <Column
          field="qty"
          showFilterMenu={false}
          header="Qty"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.qty}
            </div>
          )}
        />
        <Column
          field="buy_sell"
          showFilterMenu={false}
          header="B/S"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.buy_sell == 0 ? "Buy" : "Sell"}
            </div>
          )}
        />
        <Column
          field="position_rate"
          showFilterMenu={false}
          header="Position Rate"
        />
        <Column field="rate" showFilterMenu={false} header="Rate" />
        <Column
          field="profit_loss"
          showFilterMenu={false}
          header="P/L"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.profit_loss >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.profit_loss}
            </div>
          )}
        />

        <Column
          field="brokerage_amt"
          body={(rowData) => (
            <>
              <div>{rowData.brokerage_amt}</div>
            </>
          )}
          showFilterMenu={false}
          header="Brokerage"
        />

        <Column field="volume" showFilterMenu={false} header="Volume " />

        <Column field="deposit" showFilterMenu={false} header="Deposit " />

        <Column field="trade_msg" showFilterMenu={false} header="Details" />
        <Column
          field="date_created"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Date/Time"
          body={(rowData) => (
            <div>{moment(rowData.date_created).format("DD-MMM HH:mm:ss")}</div>
          )}
          sortable
        />
      </DataTable>
    </Dialog>
  );
}
