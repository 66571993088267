import React from "react";
import AlertCard from "./AlertCard";
import { apiCall } from "../../Backend/Backend";
import UpdateNotification from "../Common/Notification";

export default function Alerts({
  scrollHieght,
  notificationList,
  notificationTypes,
  load_notification,
}) {
  return (
    <>
      <UpdateNotification update={() => load_notification()} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          width: "100%",
          height: scrollHieght,
        }}
      >
        {notificationList &&
          notificationList.map((i, t) => <AlertCard data={i} key={i.id} />)}
      </div>
    </>
  );
}
