import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();
const height = window.innerHeight;

export default function LoginLogs(props) {
  const [loading, setLoading] = React.useState(false);
  const [loginLogs, setLoginLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_admin_login();
  }, []);

  const load_admin_login = async () => {
    const response = await apiCall("/load_admin_login");
    setLoading(false);
    setLoginLogs(response.login_logs);
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={loginLogs}
        size="small"
      >
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="remarks"
          header="Devices"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="version"
          header="Version"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="ip"
          header="IP"
        ></Column>
        <Column
          style={{ width: "40%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
