import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { ToWords } from "to-words";

import { useNotification } from "../../../../Notification/NotificationContext";
import { FlexItem } from "../../../Overview/Styled";
import { BalanceInput, BalanceLabel } from "../../../Add/AddUser";
import { apiCall } from "../../../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../../../Common/NumberFormatIn";
import { FaChartPie, FaLock, FaUser, FaUserCheck } from "react-icons/fa";
import ClientDetailsCard from "../../ClientDetailsCard";

export default function AddBrokerModal({
  visible,
  position,
  setVisible,
  masterId,
  reload,
}) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [selectedClient, setSelectedClient] = React.useState([]);
  const [clients, setUsers] = React.useState([]);

  const [formData, setFormData] = React.useState({
    name: "",
    username: "",
    password: "",
  });

  const formConfig = [
    { key: "name", placeholder: "e John Bond", type: "text", Icon: FaUser },
    {
      key: "username",
      placeholder: "eg. JohnB07",
      type: "text",
      Icon: FaUserCheck,
      maxlength: 10,
    },
    {
      key: "password",
      placeholder: "******",
      type: "text",
      Icon: FaLock,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    load_masters();
  }, []);

  const load_masters = async () => {
    const response = await apiCall("/load_masters");
    setLoading(false);
    setUsers(response.users);
    setSelectedClient(response.users.find((user) => user.id === masterId));
  };

  const add_broker = async () => {
    const validations = [
      {
        condition: !formData?.name,
        message: "Validation failed: Password field is empty.",
      },
      {
        condition: !formData?.username,
        message: "Validation failed: Username field is empty.",
      },
      {
        condition:
          !formData?.username ||
          formData.username.length > 14 ||
          formData.username.length < 2,
        message:
          "Validation failed: Username must be between 2 and 14 characters.",
      },
      {
        condition: !formData?.password,
        message: "Validation failed: Password field is empty.",
      },
      {
        condition: !formData?.password || formData.password.length < 3,
        message: "Validation failed: Password must be more than 3 characters.",
      },
    ];

    // Check for the first validation error
    const error = validations.find((validation) => validation.condition);
    if (error) {
      addNotification(error.message, "error");
      return;
    }

    if (!loading) {
      setLoading(false);
      let data = {
        name: formData.name,
        username: formData.username,
        password: formData.password,
        m_id: selectedClient?.id,
      };
      try {
        setLoading(true);
        const response = await apiCall("/add_broker", data);
        addNotification(response.message, "success");

        if (response.error == "False") {
          setFormData({
            name: "",
            username: "",
            password: "",
          });

          setSelectedClient({});
          reload();
          setVisible();
        }
      } catch (error) {
        console.error("Error adding client:", error);
      } finally {
        reload();
        setLoading(false);
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        severity="danger"
        onClick={() => setVisible(false)}
      />
      <Button
        label="Add "
        icon="pi pi-plus"
        onClick={() => add_broker()}
        style={{ backgroundColor: COLORSCHEMEA.THEMEFONT }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div
          style={{ color: COLORSCHEMEA.WHITECOLOR, textTransform: "uppercase" }}
        >
          {"  ADD BROKER : " + selectedClient?.username}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <ClientDetailsCard
        Icon={FaChartPie}
        dropDown
        title={"Masters"}
        name={"m_id"}
        brokerList={clients}
        brokerId={selectedClient}
        onChange={(e) => setSelectedClient(e)}
        placeholder={"Select "}
        flex="1"
      />
      {Object.keys(formData).map((key) => {
        if (key === "fixed_rent") return null; // Skip "fixed_rent"
        const config = formConfig.find((item) => item.key === key);
        const maxLength = config?.maxlength ? config.maxlength : "unset";
        return (
          <ClientDetailsCard
            Icon={config?.Icon}
            input
            type={config?.type || "text"} // Default to 'text' if no type is specified
            name={key}
            title={config?.title || key}
            key={key}
            value={formData[key]}
            onChange={handleChange}
            placeholder={config?.placeholder || ""}
            maxLength={maxLength}
            flex={1}
          />
        );
      })}
    </Dialog>
  );
}
