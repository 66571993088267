// SoundUtility.js
const pendingaudio = "./pending.mp3";
const surveyaudio = "./survey.mp3";
const fsqaudio = "./fsqoff.mp3";
const booundryaudio = "./boundry.mp3";

export const pendningplaySound = () => {
  const pendingsound = new Audio(pendingaudio);
  pendingsound.volume = 1.0;
  pendingsound.play().catch((error) => {
    console.error("Error playing pending audio:", error);
  });
};

export const surveyPlaysound = () => {
  const surveysound = new Audio(surveyaudio);
  surveysound.volume = 1.0;
  surveysound.play().catch((error) => {
    console.error("Error playing survey audio:", error);
  });
};

export const boundryPlaysound = () => {
  const boundsound = new Audio(booundryaudio);
  boundsound.volume = 1.0;
  boundsound.play().catch((error) => {
    console.error("Error playing boundary audio:", error);
  });
};

export const fsqoffPlaysounnd = () => {
  const fsqoffsound = new Audio(fsqaudio);
  fsqoffsound.volume = 1.0;
  fsqoffsound.play().catch((error) => {
    console.error("Error playing FSQ off audio:", error);
  });
};
