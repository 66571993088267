import React from "react";
import styled from "styled-components";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import NumberFormatIn from "../Common/NumberFormatIn";

const CardContainer = styled.div`
  border-width: 0;
  border-style: solid;
  border-right-width: 0.5px;
  flex: 1;
  border-bottom-width: 0.5px;
  padding: 10px;
  min-width: 50%;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${COLORSCHEMEA.THEMEGRAY};
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) =>
    props.valueColor || COLORSCHEMEA.THEMEFONT}; // Use dynamic color
`;

export default function StatCard({ title, value, valueColor }) {
  return (
    <CardContainer>
      {title && <Title>{title}</Title>}
      <Value valueColor={valueColor}>
        <NumberFormatIn value={value && value} />
      </Value>
    </CardContainer>
  );
}
