import React from "react";
import {
  FaUser,
  FaUserCheck,
  FaChartPie,
  FaDollarSign,
  FaLock,
} from "react-icons/fa";
import { MdAccountBalance, MdCreditCard } from "react-icons/md";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { Button } from "primereact/button";
import moment from "moment";
import { apiCall } from "../../../Backend/Backend";
import ClientDetailsCard from "./ClientDetailsCard";
import { useNotification } from "../../Notification/NotificationContext";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import styled from "styled-components";
import NumberFormatIn from "../../Common/NumberFormatIn";

import { ToWords } from "to-words";

const ResponsiveDiv = styled.div`
  display: flex;
  height: calc(100vh - 55px);

  width: 100%;
  padding: 10px;

  /* Default layout: row direction */
  flex-direction: row;

  /* Media query for screen widths less than 1000px */
  @media (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll; /* Makes the container scrollable if content overflows */
    padding: 0px;
  }
`;

const StyledDiv = styled.div`
  border-style: solid;
  height: calc(100vh - 155px);
  scroll-behavior: smooth;
  overflow-y: scroll;
  border-width: 1px;
  border-color: ${(props) => props?.borderColor || COLORSCHEMEA.THEMEFONT};
  border-radius: 5px;
  flex: 1;
  margin-left: 10px;
  @media (max-width: 1000px) {
    margin: 10px;
    &:last-child {
      margin-top: 0;
    }
  }
`;

const Container = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  border-bottom-style: solid;
  border-width: 0.4px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 12px;
`;

export default function EditClient({ clientData, reload }) {
  const [loading, setLoading] = React.useState(true);
  const { addNotification } = useNotification();
  const [brokerList, setBrokerList] = React.useState([]);
  const [clientDataArray, setClientData] = React.useState([]);
  const [adminMargin, setAdminMargin] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: "",
    username: "",
    password: "",
    margin: "",
    min_brokerage: "",
    rent: "",
    trade_margin: "",
    script_margin: "",
    alias: "",
  });

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "",
        plural: "",
        symbol: "₹",
        fractionalUnit: {
          name: "Points",
          plural: "Points",
          symbol: "",
        },
      },
    },
  });

  const formConfig = [
    { key: "name", placeholder: "e.g. John Bond", type: "text", Icon: FaUser },
    {
      key: "username",
      placeholder: "e.g. JohnB07",
      type: "text",
      Icon: FaUserCheck,
      maxlength: 10,
    },
    {
      key: "password",
      placeholder: "******",
      type: "text",
      Icon: FaLock,
    },
    {
      key: "balance",
      placeholder: "e.g. 1,00,000",
      type: "number",
      Icon: MdAccountBalance,
    },
    {
      key: "credit",
      placeholder: "e.g. 1,00,00,000",
      type: "number",
      Icon: MdCreditCard,
    },
    {
      key: "rent",
      placeholder: "e.g. 18%",
      type: "number",
      title: "Rent Per Year (%)",
      Icon: FaDollarSign,
    },
    {
      key: "fixed_rent",
      placeholder: "e.g. 1,00,00,000",
      type: "number",
      Icon: FaDollarSign,
    },
    // {
    //   key: "brokerage",
    //   placeholder: "e.g. 0.250",
    //   type: "number",
    //   Icon: FaDollarSign,
    // },
    {
      key: "margin",
      placeholder: "e.g. 50%",
      type: "number",
      title: "Master Partnership (%)",
      Icon: FaChartPie,
    },
    {
      key: "min_brokerage",
      placeholder: "e.g. 0.005",
      type: "number",
      title: "Min Brokerage Per Cr",
      Icon: FaDollarSign,
    },
    {
      key: "trade_margin",
      placeholder: "e.g. 2,00,000",
      type: "number",
      title: "Total Trade Margin",
      Icon: FaChartPie,
    },
    {
      key: "script_margin",
      placeholder: "e.g. 2,00,000",
      type: "number",
      title: "Per Script Margin",
      Icon: FaChartPie,
    },
    {
      key: "alias",
      placeholder: "e.g. Bond007",
      type: "text",
      Icon: FaUser,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
        ...(name === "balance" && { fixed_rent: value }), // Set fixed_rent to balance value if balance changes
      };

      // Check if margin exceeds admin margin
      if (
        name === "margin" &&
        parseFloat(value) > parseFloat(adminMargin[0]?.margin)
      ) {
        addNotification(
          "Error: Margin cannot exceed Admin's Margin " +
            parseFloat(adminMargin[0]?.margin),
          "error"
        );
        newData.margin = "0"; // Set margin to 0 if it exceeds admin margin
      }

      return newData;
    });
  };

  const validateData = (userData) => {
    for (const [key, value] of Object.entries(userData)) {
      if (key !== "alias" && key !== "password") {
        // Exclude alias from validation
        if (value === "" || value === null || value === undefined) {
          addNotification(
            `Validation failed: ${key
              .replace(/_/g, " ")
              .toUpperCase()} is required.`,
            "error"
          );
          return false;
        }
      }
    }
    // Validate username length
    if (userData.username) {
      if (userData.username.length < 3 || userData.username.length > 9) {
        addNotification(
          "Validation failed: Username must be more than 2 characters and less than 10 characters.",
          "error"
        );
        return false;
      }
    }

    if (userData.password != "") {
      if (userData.password.length < 3) {
        addNotification(
          "Validation failed: Password must be more than 2 characters.",
          "error"
        );
        return false;
      }
    }

    // Validate numeric fields in userData
    for (const key of [
      "margin",
      "trade_margin",
      "script_margin",
      "rent",
      "min_brokerage",
    ]) {
      if (userData[key] !== undefined) {
        if (isNaN(userData[key]) || Number(userData[key]) < 0) {
          addNotification(
            `Validation failed:${key
              .replace(/_/g, " ")
              .toUpperCase()} must be greater than 0.`,
            "error"
          );
          return false;
        }
      }
    }

    // Additional validation for credit >= balance
    if (userData.trade_margin && userData.script_margin) {
      if (Number(userData.trade_margin) < Number(userData.script_margin)) {
        addNotification(
          "Validation failed: Script margin must be less than or equal to Trade margin.",
          "error"
        );
        return false;
      }
    }

    return true; // All values are valid
  };

  React.useEffect(() => {
    load_masters_data();
  }, [clientData.id]);

  const load_masters_data = async () => {
    let sendData = {
      m_id: clientData.id,
    };
    const response = await apiCall("/load_masters_data", sendData);
    setLoading(false);
    setClientData(response.masters);
    const user = response.masters; // Assuming you want to fill the form with the first user's data
    setFormData({
      name: user.name || "",
      username: user.username || "",
      password: "", // Keep this empty for security unless otherwise handled
      rent: user.rent || "",
      // fixed_rent: user.fixed_rent_amount || "",
      // brokerage: user.brokerages || "",
      margin: user.margin || "",
      min_brokerage: user.min_brokerage || "",
      trade_margin: user.trade_margin || "",
      script_margin: user.script_wise_margin || "",
      alias: user.alias || "",
    });
  };
  React.useEffect(() => {
    load_admins_margin();
  }, []);

  const load_admins_margin = async () => {
    const response = await apiCall("/load_admins_margin");

    setAdminMargin(response.admin_margin);
    setLoading(false);
  };
  const editClient = async () => {
    let data = {
      name: formData.name,
      password: formData.password,
      rent: formData.rent,
      margin: formData.margin,
      trade_margin: formData.trade_margin,
      script_margin: formData.script_margin,
      alias: formData.alias,
      min_brokerage: formData.min_brokerage,
      m_id: clientData.id,
    };
    // Validate data
    const isValid = validateData(formData);

    if (!isValid) {
      console.error("Validation failed: Some fields are empty or invalid.");
      return; // Exit the function if validation fails
    }

    // Proceed with API call if data is valid
    try {
      setLoading(true);
      const response = await apiCall("/edit_master", data);
      addNotification(response.message, "success");
    } catch (error) {
      console.error("Error adding client:", error);
    } finally {
      reload();
      setLoading(false);
    }
  };

  return (
    <ResponsiveDiv>
      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <Container>
          <Content>
            <Title>Edit Client</Title>
            <Subtitle>Basic Details</Subtitle>
          </Content>
          <Button
            type="button"
            size="small"
            label="Update"
            severity="primary"
            onClick={editClient}
          />
        </Container>
        {Object.keys(formData).map((key) => {
          const config = formConfig.find((item) => item.key === key);
          const maxLength = config?.maxlength ? config.maxlength : "unset";
          const grayKeys = ["margin", "min_brokerage", "rent"];
          return (
            <ClientDetailsCard
              Icon={config?.Icon}
              input
              type={config?.type || "text"} // Default to 'text' if no type is specified
              name={key}
              title={config?.title || key}
              key={key}
              value={formData[key]}
              onChange={handleChange}
              placeholder={config?.placeholder || ""}
              maxLength={maxLength}
              disabled={key == "username"}
              subDetailsLeft={(() => {
                if (!adminMargin || adminMargin.length === 0) {
                  return ""; // Return empty string if adminMargin is not available
                }

                const detailsMap = {
                  margin: `Admin's Margin : ${
                    (parseFloat(adminMargin[0].margin) || 0) -
                    parseFloat(formData[key] || 0)
                  }%`,
                  rent: `Allowed Min Rent : ${
                    parseFloat(adminMargin[0].rent) || 0
                  }%`,
                  min_brokerage: `Allowed Brokerage : ${
                    parseFloat(adminMargin[0].min_brokerage).toFixed(3) || 0
                  } to ${
                    parseFloat(adminMargin[0].max_brokerage).toFixed(3) || 0
                  }`,
                };
                return detailsMap[key] || "";
              })()}
              color={grayKeys.includes(key) ? COLORSCHEMEA.THEMELIGHT : ""}
              numberToText={
                [
                  "margin",
                  "trade_margin",
                  "rent",
                  "min_brokerage",
                  "script_margin",
                ].includes(key) &&
                formData[key] !== "" &&
                !isNaN(formData[key])
                  ? toWords.convert(
                      formData[key] * (key === "brokerage" ? 100000 : 1)
                    )
                  : ""
              }
            />
          );
        })}
      </StyledDiv>
    </ResponsiveDiv>
  );
}
