import React from "react";
import { Avatar } from "primereact/avatar";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import moment from "moment/moment";

export default function AlertCard({ data }) {
  const {
    color = "#afafc6a8",
    username,
    msg,
    date_created,
    notification_type,
  } = data;
  function getInitials() {
    const words = notification_type.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
    return initials;
  }
  function getColor(initial) {
    switch (initial) {
      case "S":
        return "var(--orange-600)";
      case "P":
        return "var(--primary-color)";
      case "L":
        return "var(--red-300)";
      case "B":
        return "var(--cyan-600)";
      default:
        return "var(--primary-color)";
    }
  }
  return (
    <div
      style={{
        minHeight: 60,
        borderBottomStyle: "solid",
        borderWidth: 0.3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          label={getInitials()}
          size="large"
          style={{
            backgroundColor: getColor(getInitials().charAt(0)),
            color: "#ffffff",
            height: 40,
            width: 42,
          }}
        />
      </div>

      <div
        style={{
          flex: 1,
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 15,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          {username}
          <div
            style={{
              fontSize: 12,
              color: COLORSCHEMEA.THEMEGRAY,
            }}
          >
            {moment(date_created).format("hh:mm A")}
          </div>
        </div>

        <div style={{ fontSize: 12, color: COLORSCHEMEA.THEMEGRAY }}>{msg}</div>
      </div>
    </div>
  );
}
