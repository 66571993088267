import React from "react";
import { Avatar } from "primereact/avatar";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";

export default function OnlineCard({ username = "", type = "" }) {
  function getInitials(username) {
    const words = username.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
    return initials;
  }
  return (
    <div
      style={{
        minHeight: 60,
        borderBottomStyle: "solid",
        borderWidth: 0.3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          label={getInitials(username)}
          size="large"
          style={{
            backgroundColor: COLORSCHEMEA.THEMEFONT,
            color: "#ffffff",
            height: 40,
            width: 42,
          }}
        />
      </div>

      <div
        style={{
          flex: 1,
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 15, fontWeight: "bold" }}>
          {username?.toUpperCase()}
        </div>
        <div style={{ fontSize: 12, color: COLORSCHEMEA.THEMEGRAY }}>
          {type?.toUpperCase()}
        </div>
      </div>
      <div
        style={{
          height: 10,
          width: 10,
          background: "green",
          borderRadius: 30,
          marginRight: 10,
        }}
      ></div>
    </div>
  );
}
