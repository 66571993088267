import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { apiCall } from "../../../Backend/Backend";
import moment from "moment";

const php = new Php();
const height = window.innerHeight;

export default function DepositLogs({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_admins_deposit();
  }, []);

  const load_admins_deposit = async () => {
    if (!loading) {
      setLoading(true);

      const response = await apiCall("/load_admins_deposit");
      setLoading(false);
      setClientLedgerLogs(response.deposit_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLedgerLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
          body={(rowData) => (
            <div>{moment(rowData.date_created).format("DD-MMM-YY")}</div>
          )}
        />
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="remarks"
          header="Remarks"
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="amount"
          body={(rowData) =>
            rowData.in_out == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>
                {Math.abs(rowData.amount)}
              </div>
            ) : null
          }
          header="Credit"
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="amount"
          body={(rowData) =>
            rowData.in_out == 1 ? (
              <div style={{ color: NewTheme.redcolor }}>
                {Math.abs(rowData.amount)}
              </div>
            ) : null
          }
          header="Debit"
        />

        <Column
          sortable
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="new_balance"
          body={(rowData) => (
            <div
              style={{
                fontWeight: "bolder",
              }}
            >
              {/* <NumberFormatIn value={rowData.old_balance} />
            <span
              style={{
                color: NewTheme.MainColor,
                fontWeight: "bolder",
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              {"  ->  "}
            </span> */}
              <NumberFormatIn value={rowData.new_balance} />
            </div>
          )}
          header="Balance"
        ></Column>
      </DataTable>
    </div>
  );
}
