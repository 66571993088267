import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();
const height = window.innerHeight;

export default function Locks({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientLockLogs, setClientLockLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_locks();
  }, []);

  const load_users_locks = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        c_id: clientData.id,
      };
      const response = await apiCall("/load_users_locks", sendData);
      setLoading(false);

      setClientLockLogs(response.lock_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLockLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="minutes"
          header="Lock Minutes"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="block_time"
          header="Block Time"
        ></Column>

        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="status"
          body={(rowData) => (rowData.status == 0 ? "Pending" : "Triggered")}
          header="Status"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="msg"
          header="Details"
        ></Column>
      </DataTable>
    </div>
  );
}
