import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSidebar } from "../Sidebar/SidebarContext";
import { NewTheme } from "../Theme/Theme";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";

export default function TopbarMost(props) {
  const { toggleSidebar } = useSidebar();
  return (
    <div
      style={{
        height: props.height ? props.height : 55,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        background: COLORSCHEMEA.THEMEFONT,
        color: "white",
      }}
    >
      {props.noSidebar || props.onlyHeader ? null : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 50,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon
              style={{ height: 20, width: 20 }}
              icon={faBars}
              size="lg"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              flex: 1,
              minWidth: 250,
            }}
          >
            <div
              style={{
                marginLeft: 5,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              {props.name}
            </div>
            {props.subTitle && (
              <div
                style={{
                  marginLeft: 5,
                  fontWeight: "bold",
                  fontSize: 12,
                  textAlign: "left",
                  flex: 1,
                }}
              >
                {props.subTitle}
              </div>
            )}
          </div>
        </div>
      )}
      {props.onlyHeader ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 5,
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "left",
              flex: 1,
            }}
          >
            {props.name}
          </div>
        </div>
      ) : null}
      <div style={{ flex: 3 }}>{props.cmp}</div>
    </div>
  );
}
