import React from "react";
import styled from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Edit(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();

  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    load_brokers_data();
  }, [props.data.id]);

  const load_brokers_data = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        b_id: props.data.id,
      };
      php.load_brokers_data(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientData(r.users);
          setName(r.users?.name);
          setUserame(r.users?.username);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const edit_broker = (e) => {
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (password != "" && password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          // m_id: props.data.master_id,
          b_id: props.data.id,
          name: name,
          password: password,
        };

        php.edit_broker(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            props.reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  return (
    <Card>
      <BalanceWrapper>
        <BalanceContainer>
          <BalanceLabel>Master </BalanceLabel>
          <BalanceInput
            style={{
              backgroundColor: "#edebfa",
              color: NewTheme.MainColor,
              fontWeight: "bold",
            }}
            type="text"
            value={clientData?.master}
            disabled
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceLabel>Name </BalanceLabel>
          <BalanceInput
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter In Name "
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceLabel>Username </BalanceLabel>
          <BalanceInput
            style={{
              backgroundColor: "#edebfa",
              color: NewTheme.MainColor,
              fontWeight: "bold",
            }}
            type="text"
            value={username}
            disabled
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceLabel>Password</BalanceLabel>
          <BalanceInput
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password To Change"
          />
        </BalanceContainer>
        <BalanceUpdateButton onClick={edit_broker}>UPDATE</BalanceUpdateButton>
      </BalanceWrapper>
    </Card>
  );
}
