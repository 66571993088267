import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();
const height = window.innerHeight;

export default function RejectionLogs({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientRejectionLogs, setClientRejectionLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_rejection();
  }, []);

  const load_users_rejection = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        c_id: clientData.id,
      };
      const response = await apiCall("/load_users_rejection", sendData);
      setLoading(false);

      setClientRejectionLogs(response.rejection_logs);
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientRejectionLogs}
        size="small"
      >
        <Column
          style={{ width: "30%" }}
          sortable
          showFilterMenu={false}
          field="symbol"
          header="Symbol"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="qty"
          header="Qty"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) =>
            rowData.type == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>Buy</div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>Sell</div>
            )
          }
          header="Buy/Sell"
        ></Column>
        <Column
          style={{ width: "40%" }}
          showFilterMenu={false}
          field="msg"
          body={(rowData) => {
            var data = JSON.parse(rowData.rejection_array);
            return data.msg;
          }}
          header="Error"
        ></Column>
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
