export const COLORSCHEMEA = {
  THEMEBACKGROUND: "#eef2f6",
  THEMEFONT: "var(--primary-color)",
  THEMEGRAY: "gray",
  NOTIFICATIONRED: "#CB4B32",
  NOTIFICATIONGREEN: "#5CBA63",
  THEMELIGHT: "var(--primary-400)",

  GREENCOLOR: "#019c6b",
  GREENCOLOREXCEL: "#019c6b",
  REDCOLOR: "#df1524",
  REDCOLOREXCEL: "#df1524",
  LIGHTMAIN: "#edebfa",
  NEWREDTEXT: "#DC143C",
  LIGHTRED: "#f7babe3b",

  WHITECOLOR: "#ffffff",
  BLACKCOLOR: "#000000",

  ORANGECOLOR: "#ff922a",
  PURPLECOLOR: "#7654d4",
};
