// import React from "react";
// import logo from "../../Assets/Images/logo.jpg";
// import { useUserContext } from "./LoginContext";

// import { BrowserView } from "react-device-detect";

// function LoginPage() {
//

//   return (
//     <LoginContainer>
//
//         <Logo src={logo} alt="Logo" />
//
//         <SmallButton onClick={loading ? null : handleLogin} type="button">
//           {loading ? "loading.." : "Login"}
//         </SmallButton>
//       </LoginForm>
//     </LoginContainer>
//   );
// }

// export default LoginPage;

import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import { useUserContext } from "./LoginContext";
import { Input, LoginContainer, LoginForm, Logo, SmallButton } from "./styles";

// Add event listener to detect Enter key press

export default function Login() {
  const [width, height] = useWindowSize();
  const { loading, handleInputs, handleLogin } = useUserContext() ?? {};

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };
  return (
    <div
      onKeyDown={handleKeyDown}
      style={{
        height: height,
        width: width,
        background: COLORSCHEMEA.THEMEBACKGROUND,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          background: "white",
          padding: 20,
          width: "40%",
          minWidth: 400,
          borderRadius: 10,
        }}
      >
        <div>LOGO</div>
        <div
          style={{
            color: COLORSCHEMEA.THEMEFONT,
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          Hi, Welcome Back
        </div>

        <div
          style={{
            color: COLORSCHEMEA.THEMEGRAY,
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          Enter Your Credential to Continue
        </div>

        <div
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "gray",
            background: COLORSCHEMEA.THEMEBACKGROUND,
            marginBottom: 10,
            marginTop: 30,
          }}
        >
          <div
            style={{
              color: COLORSCHEMEA.THEMEFONT,
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            SERVER
          </div>
          <Input
            type="text"
            placeholder="TEST"
            id="server"
            // value={server}
            onChange={handleInputs}
          />
        </div>

        <div
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "gray",
            background: COLORSCHEMEA.THEMEBACKGROUND,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <div
            style={{
              color: COLORSCHEMEA.THEMEFONT,
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            USERNAME
          </div>
          <Input
            type="text"
            id="username"
            placeholder="ABCD001"
            //  value={username}
            onChange={handleInputs}
          />
        </div>
        <div
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "gray",
            background: COLORSCHEMEA.THEMEBACKGROUND,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <div
            style={{
              color: COLORSCHEMEA.THEMEFONT,
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            PASSWORD
          </div>
          <Input
            type="password"
            id="password"
            placeholder="abcd@1234"
            // value={password}
            onChange={handleInputs}
          />
        </div>

        <SmallButton onClick={loading ? null : handleLogin} type="button">
          {loading ? "loading.." : "Login"}{" "}
        </SmallButton>
      </div>
    </div>
  );
}
