import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { ToWords } from "to-words";
import moment from "moment/moment";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { useNotification } from "../../Notification/NotificationContext";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { apiCall } from "../../../Backend/Backend";
import { Dropdown } from "primereact/dropdown";
import { BalanceLabel } from "../../Masters/Add/AddUser";
import { BalanceInput } from "../../Masters/Newui/ClientDetailsCard";
import { FlexItem } from "../../Masters/Overview/Styled";
const height = window.innerHeight;

export default function BankBalanceModal({
  visible,
  position,
  setVisible,
  clientUsername,
  usersId,
  reload,
  deposit,
  adminId,
}) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "",
        plural: "",
        symbol: "₹",
        fractionalUnit: {
          name: "Points",
          plural: "Points",
          symbol: "",
        },
      },
    },
  });

  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [balance, setBalance] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const [masterFilter, setMasterFilter] = React.useState([]);
  const [selectedMaster, setSelectedMaster] = React.useState(null);
  const [date, setDate] = React.useState([
    moment().startOf("month").toDate(),
    new Date(),
  ]);
  React.useEffect(() => {
    load_admin_bank_ledger();
  }, [usersId]);

  const load_admin_bank_ledger = async (e) => {
    if (!loading) {
      setLoading(true);
      const params = {
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 00:00:00")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
      };
      const response = await apiCall("/load_admin_bank_ledger", params);

      setMasterFilter(response.masters);
      setClientLedgerLogs(response.ledger_logs);
      setLoading(false);
    }
  };

  const balanceAdd = async () => {
    if (
      isNaN(parseFloat(balance)) ||
      parseFloat(balance) == null ||
      parseFloat(balance) == "" ||
      parseFloat(balance) == undefined
    ) {
      addNotification("Enter Number Greater Than 0 ", "error");
    } else {
      if (!loading) {
        setLoading(true);
        let sendData = {
          balance: parseFloat(balance).toFixed(3),
          remarks: remarks,
          m_id: selectedMaster?.id,
        };

        const response = await apiCall("/add_admin_bank_balance", sendData);
        console.log(response);  
        if (response.error == "False") {
          // setVisible();
          load_admin_bank_ledger();
          addNotification(response.message, "success");
        } else {
          addNotification(response.message, "error");
        }
        setLoading(false);
        reload();
      }
    }
  };

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  return (
    <Dialog
      header={
        <div style={{ color: COLORSCHEMEA.WHITECOLOR }}>
          {"  Add Bank Balance : "}
          {clientUsername && clientUsername}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "90vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      // footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div style={{ flex: 1.5 }}>
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel>
              {"Balance ( "}
              <span
                style={{
                  color:
                    parseFloat(deposit) >= 0
                      ? COLORSCHEMEA.GREENCOLOR
                      : COLORSCHEMEA.REDCOLOR,
                }}
              >
                <NumberFormatIn value={parseFloat(deposit)} />{" "}
              </span>
              {" )"}
            </BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BalanceInput
              type="number"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
              placeholder=" Amount In +/-"
              style={{
                width: "100%",
              }}
              autoFocus
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel>{"Remarks"}</BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BalanceInput
              type="text"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="e.g. Cash in/out ( +/- )"
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel>{"Select Admin"}</BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 160,
                height: 40,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedMaster(null);
                } else {
                  setSelectedMaster(e.value);
                }
              }}
              value={selectedMaster}
              options={masterFilter}
              optionLabel="username"
              placeholder=" "
              filter
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignSelf: "flex-end",
            gap: 10,
          }}
        >
          <Button
            label={balance >= 0 ? "Deposit " : "Withdraw"}
            onClick={() => balanceAdd()}
            style={{
              backgroundColor:
                balance >= 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR,
              borderColor:
                balance >= 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR,
              height: 40,
            }}
          />
          <Button
            label="Cancel"
            // icon="pi pi-times"
            outlined
            severity="danger"
            style={{ height: 40 }}
            onClick={() => setVisible(false)}
          />
        </div>
      </div>{" "}
      <div className="card">
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollHeight={height - 150}
          scrollable
          // filters={filters}
          value={clientLedgerLogs}
          size="small"
          paginator
          rows={10}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            style={{ width: "10%" }}
            sortable
            showFilterMenu={false}
            field="date_created"
            header="Date/Time"
            body={(rowData) => (
              <div>{moment(rowData.date_created).format("DD-MMM-YY")}</div>
            )}
          />
          <Column
            style={{ width: "10%" }}
            sortable
            showFilterMenu={false}
            field="to_admin_username"
            header="Admin"
            body={(rowData) => <div>{rowData.to_admin_username}</div>}
          />
          <Column
            style={{ width: "30%" }}
            sortable
            showFilterMenu={false}
            field="remarks"
            header="Remarks"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="credit"
            body={(rowData) =>
              rowData.in_out == 0 ? (
                <div style={{ color: COLORSCHEMEA.GREENCOLOR }}>
                  {Math.abs(rowData.credit)}
                </div>
              ) : null
            }
            header="Credit"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="debit"
            body={(rowData) =>
              rowData.in_out == 1 ? (
                <div style={{ color: COLORSCHEMEA.REDCOLOR }}>
                  {Math.abs(rowData.debit)}
                </div>
              ) : null
            }
            header="Debit"
          />

          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="old_balance"
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bolder",
                }}
              >
                <NumberFormatIn value={rowData.old_balance} />
              </div>
            )}
            header="Old Balance"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="new_balance"
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bolder",
                }}
              >
                <NumberFormatIn value={rowData.new_balance} />
              </div>
            )}
            header="Balance"
          />
        </DataTable>
      </div>
    </Dialog>
  );
}
