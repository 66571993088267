import React, { useState } from "react";
import { ToggleButton } from "primereact/togglebutton";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
function toTitleCase(str) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
}
export default function ToggleCard({
  name,
  value,
  details,
  onClick = () => {},
  detailsColor,
}) {
  return (
    <div
      style={{
        display: "flex",
        margin: 5,
        marginTop: 10,
        minHeight: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          paddingLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexDirection: "column",
        }}
      >
        <div>{toTitleCase(name)}</div>
        <div
          style={{
            fontSize: 12,
            color: detailsColor ? detailsColor : COLORSCHEMEA.THEMEGRAY,
          }}
        >
          {details ? details : ""}
        </div>
      </div>
      <div>
        <ToggleButton
          onLabel="ON"
          offLabel="OFF"
          style={{
            height: 30,
            width: 80,
            marginRight: 10,
            backgroundColor: value ? COLORSCHEMEA.THEMEFONT : "inherit",
            color: value ? "#ffffff" : "unset",
            borderColor: value ? COLORSCHEMEA.THEMEFONT : "unset",
            borderWidth: 1,
          }}
          checked={value}
          onChange={() => onClick()}
        />
      </div>
    </div>
  );
}
