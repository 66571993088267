import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import RightView from "./RightView";
import LeftView from "./LeftView";

export default function Home() {
  const [width, height] = useWindowSize();
  return (
    <div style={{ display: "flex", height: height, width: width }}>
      <LeftView />
      <RightView />
    </div>
  );
}
