import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { ToWords } from "to-words";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { apiCall } from "../../../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../../../Common/NumberFormatIn";
import { useNotification } from "../../../../Notification/NotificationContext";
import { BalanceInput, BalanceLabel } from "../../../Add/AddUser";
import { FlexItem } from "../../../Overview/Styled";
import LoadingComponent from "../../../../Common/LoadingComponent";

const height = window.innerHeight;

export default function DepositModal({
  visible,
  position,
  setVisible,
  clientUsername,
  usersId,
  reload,
  credit,
}) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [creditValue, setCredit] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filters["global"] ? filters["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const load_masters_deposit = React.useCallback(async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        m_id: usersId,
      };
      const response = await apiCall("/load_masters_deposit", sendData);
      setLoading(false);
      setClientLedgerLogs(response?.ledger_logs);
    }
  }, [usersId]);

  React.useEffect(() => {
    load_masters_deposit();
  }, [load_masters_deposit]);

  const creditInputRef = React.useRef(null);

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);
      let sendData = {
        m_id: usersId,
        creditValue: parseFloat(creditValue).toFixed(3),
        remarks: remarks,
      };

      const response = await apiCall("/add_masters_deposit", sendData);
      if (response.error == "False") {
        addNotification(response.message, "success");
        load_masters_deposit();
        setCredit("");
        setRemarks("");
        setTimeout(() => {
          creditInputRef.current?.focus();
        }, 100);
      } else {
        addNotification(response.message, "error");
      }
      setLoading(false);
      reload();
    }
  };

  return (
    <Dialog
      header={
        <div
          style={{ color: COLORSCHEMEA.WHITECOLOR, textTransform: "uppercase" }}
        >
          {"  Add Deposit : "}
          {clientUsername && clientUsername}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "90vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div style={{ flex: 1.5 }}>
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel>
              {"Balance ( "}
              <span
                style={{
                  color:
                    parseFloat(credit) >= 0
                      ? COLORSCHEMEA.GREENCOLOR
                      : COLORSCHEMEA.REDCOLOR,
                }}
              >
                <NumberFormatIn value={parseFloat(credit)} />
              </span>
              {" )"}
            </BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BalanceInput
              ref={creditInputRef}
              type="number"
              value={creditValue}
              onChange={(e) => setCredit(e.target.value)}
              placeholder=" Amount In +/-"
              style={{
                width: "100%",
              }}
              autoFocus
            />
          </div>
        </div>
        <div style={{ flex: 2 }}>
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel>{"Remarks"}</BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BalanceInput
              type="text"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="e.g. Cash in by master"
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignSelf: "flex-end",
            gap: 10,
          }}
        >
          <Button
            label={creditValue >= 0 ? "Deposit " : "Withdraw"}
            onClick={() => handleSubmit()}
            style={{
              backgroundColor:
                creditValue >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
              borderColor:
                creditValue >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
              height: 40,
            }}
          />
          <Button
            label="Cancel"
            // icon="pi pi-times"
            outlined
            severity="danger"
            style={{ height: 40 }}
            onClick={() => setVisible(false)}
          />
        </div>
      </div>
      <div className="card">
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollHeight={height - 150}
          scrollable
          value={clientLedgerLogs}
          size="small"
          paginator
          rows={10}
          loading={loading}
          loadingIcon={() => (
            <LoadingComponent
              showRing
              showText
              withPulse={true}
              position="center"
            />
          )}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            style={{ width: "10%" }}
            sortable
            showFilterMenu={false}
            field="date_created"
            header="Date/Time"
            body={(rowData) => (
              <div>{moment(rowData.date_created).format("DD-MMM-YY")}</div>
            )}
          />
          <Column
            style={{ width: "30%" }}
            sortable
            showFilterMenu={false}
            field="remarks"
            header="Remarks"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="credit"
            body={(rowData) =>
              rowData.in_out == 0 ? (
                <div style={{ color: COLORSCHEMEA.GREENCOLOR }}>
                  {Math.abs(rowData.credit)}
                </div>
              ) : null
            }
            header="Credit"
          />
          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="debit"
            body={(rowData) =>
              rowData.in_out == 1 ? (
                <div style={{ color: COLORSCHEMEA.REDCOLOR }}>
                  {Math.abs(rowData.debit)}
                </div>
              ) : null
            }
            header="Debit"
          />

          <Column
            sortable
            style={{ width: "10%" }}
            showFilterMenu={false}
            field="new_balance"
            body={(rowData) => (
              <div
                style={{
                  fontWeight: "bolder",
                }}
              >
                <NumberFormatIn value={rowData.new_balance} />
              </div>
            )}
            header="Balance"
          ></Column>
        </DataTable>
      </div>
    </Dialog>
  );
}
