import { useWindowSize } from "@react-hook/window-size";
import React, { useState } from "react";
import TopbarMost from "../../Common/TopbarMost";
import UsersList from "./UsersList";
import SummaryNew from "./SummaryNew";
import NewOverView from "./NewOverView";
import { apiCall } from "../../../Backend/Backend";
import AddClient from "./AddClient";

export default function MastersNew() {
  const [width, height] = useWindowSize();
  const [tabs, setTabs] = useState(1);

  const [selectedClient, setSelectedClient] = React.useState([]);
  const [clients, setUsers] = React.useState([]);
  const [clientsFilter, setUsersFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    load_users();
  }, []);

  const load_users = async () => {
    const response = await apiCall("/load_masters");
    setLoading(false);
    setUsers(response.users);
    setUsersFilter(response.users);
  };

  const searchUsers = (value) => {
    var username = value;
    if (username.length > 1) {
      let script = [];
      script = clientsFilter.filter(
        (x) =>
          x.username.toLowerCase().includes(username.toLowerCase()) ||
          x.alias?.toLowerCase().includes(username?.toLowerCase())
      );
      setUsers(script);
    } else {
      setUsers(clientsFilter);
    }
  };

  return (
    <div
      style={{
        // height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopbarMost name={"MASTERS"} />
      <div style={{ flex: 1, display: "flex" }}>
        <UsersList
          clientsData={clients}
          onChange={(e) => searchUsers(e.target.value)}
          goToAdd={() => setTabs(0)}
          goToSummary={() => {
            setTabs(1);
          }}
          selectedClient={selectedClient}
          goToOverview={(e) => {
            setTabs(2);
            setSelectedClient(e);
          }}
        />
        {tabs == 0 && <AddClient reload={() => load_users()} />}
        {tabs == 1 && (
          <SummaryNew
            goToOverview={(e) => {
              setTabs(2);
              setSelectedClient(e);
            }}
            reload={() => load_users()}
            selectedClient={selectedClient}
          />
        )}
        {tabs == 2 && (
          <NewOverView
            reload={() => load_users()}
            selectedClient={selectedClient}
          />
        )}
      </div>
    </div>
  );
}
