import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";

const VolumeCard = forwardRef((props, ref) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>M2m</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color:
                props.total3 >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              <NumberFormatIn value={parseFloat(props.total3).toFixed(0)} />
            </div>
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>
            Buy Volume
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: COLORSCHEMEA.THEMEFONT,
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              <NumberFormatIn value={parseFloat(props.buyVolume).toFixed(0)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const mapstatetoProps = (state) => {
  return {
    total3: state.total3,
  };
};
export default connect(mapstatetoProps)(VolumeCard);
