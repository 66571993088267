import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";

const php = new Php();
const height = window.innerHeight;

export default function Fatak({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const [clientFatakLogs, setClientFatakLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_fatak();
  }, []);

  const load_users_fatak = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
        c_id: clientData.id,
      };

      php.load_users_fatak(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientFatakLogs(r.fatak_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientFatakLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="fatak"
          body={(rowData) => (
            <span>
              <NumberFormatIn value={rowData.fatak} />
            </span>
          )}
          header="Fatak (%)"
        ></Column>

        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="status"
          body={(rowData) => (rowData.status == 0 ? "Pending" : "Triggered")}
          header="Status"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="update_time"
          header="Trigger Time"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="msg"
          header="Details"
        ></Column>
      </DataTable>
    </div>
  );
}
