import { Dialog } from "primereact/dialog";
import React from "react";
import { Button } from "primereact/button";
import { apiCall } from "../../../Backend/Backend";

import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import {
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
} from "../../Users/Overview/Styled";
import NumberFormatIn from "../../Common/NumberFormatIn";
import ClientDetailsCard from "../../Users/Newui/ClientDetailsCard";

export default function LiveSummaryModal({
  visible,
  position,
  setVisible,
  username,
  deposit,
  weekly_pl,
}) {
  const [loading, setLoading] = React.useState(true);
  const [activeSummary, setActiveSummary] = React.useState("");
  const [totalPL, setTotalPL] = React.useState("");

  React.useEffect(() => {
    load_masters_active_summary();
  }, []);

  const load_masters_active_summary = async () => {
    const response = await apiCall("/load_masters_active_summary");
    let PL = parseFloat(weekly_pl) + parseFloat(response.data);
    setTotalPL(parseFloat(PL).toFixed(0));
    setActiveSummary(response.data);
    setLoading(false);
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: COLORSCHEMEA.THEMEFONT }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: COLORSCHEMEA.MainColor }}
      /> */}
    </div>
  );
  return (
    <Dialog
      header={
        <div
          style={{ color: COLORSCHEMEA.WHITECOLOR, textTransform: "uppercase" }}
        >
          {"  Master Live Summary : "}
          {username && username}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div style={{ marginTop: 20 }}>
        {/* <ClientDetailsCard
          title={"Balance "}
          details={<NumberFormatIn value={parseFloat(deposit).toFixed(0)} />}
          color={deposit >= 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR}
        /> */}
        <ClientDetailsCard
          title={"Booked Profit / Loss "}
          details={<NumberFormatIn value={parseFloat(weekly_pl).toFixed(0)} />}
          color={
            weekly_pl >= 0 ? COLORSCHEMEA.GREENCOLOR : COLORSCHEMEA.REDCOLOR
          }
        />
        <ClientDetailsCard
          title={"Live Profit / Loss "}
          details={
            activeSummary == null ? (
              0
            ) : (
              <NumberFormatIn value={parseFloat(activeSummary).toFixed(0)} />
            )
          }
          color={
            activeSummary != null && parseFloat(activeSummary) > 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />
        <ClientDetailsCard
          title={"Total Profit / Loss "}
          details={
            totalPL == null || isNaN(totalPL) ? (
              0
            ) : (
              <NumberFormatIn value={parseFloat(totalPL).toFixed(0)} />
            )
          }
          color={
            totalPL != null && parseFloat(totalPL) > 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />
        {/* <ClientDetailsCard
          title={"Net Amount "}
          details={
            parseFloat(parseFloat(totalPL) + parseFloat(deposit)) == null ||
            isNaN(parseFloat(parseFloat(totalPL) + parseFloat(deposit))) ? (
              0
            ) : (
              <NumberFormatIn
                value={parseFloat(
                  parseFloat(totalPL) + parseFloat(deposit)
                ).toFixed(0)}
              />
            )
          }
          color={
            parseFloat(parseFloat(totalPL) + parseFloat(deposit)) != null &&
            parseFloat(parseFloat(totalPL) + parseFloat(deposit)) > 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        /> */}

        {/* <FlexContainer>
          <FlexItem>
            <GrayText>Margin</GrayText>
            <BoldText>
              <NumberFormatIn value={parseFloat(credit).toFixed(0)} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Blocked Margin</GrayText>
            <BoldText>
              <NumberFormatIn value={parseFloat(blocked_limit).toFixed(0)} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Free Margin</GrayText>
            <BoldText>
              <NumberFormatIn
                value={parseFloat(
                  parseFloat(credit) - parseFloat(blocked_limit)
                ).toFixed(0)}
              />
            </BoldText>
          </FlexItem>
        </FlexContainer> */}
      </div>
    </Dialog>
  );
}
