import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import styled from "styled-components";
import { InputText } from "primereact/inputtext";
import { useNotification } from "../../Notification/NotificationContext";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import { apiCall } from "../../../Backend/Backend";

const height = window.innerHeight;

const CreateClientButton = React.memo(styled.button`
  background-color: ${(props) =>
    props.background ? props.background : COLORSCHEMEA.THEMEFONT};
  color: ${COLORSCHEMEA.WHITECOLOR};
  border: 1px solid ${COLORSCHEMEA.WHITECOLOR};
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #fff;
    color: ${(props) =>
      props.background ? props.background : COLORSCHEMEA.THEMEFONT};
    border: 1px solid
      ${(props) =>
        props.background ? props.background : COLORSCHEMEA.THEMEFONT};
  }
`);
export default function ScriptClient({ clientData }) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [clientBannedScript, setClientBannedScript] = React.useState([]);
  const [clientScript, setClientScript] = React.useState([]);
  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    load_masters_scripts();
  }, []);

  const load_masters_scripts = async () => {
    setLoading(true);

    const response = await apiCall("/load_masters_scripts");
    setLoading(false);
    response.script.sort((a, b) => {
      const aPriority = a.ban_id > 0 ? 1 : 0;
      const bPriority = b.ban_id > 0 ? 1 : 0;
      return bPriority - aPriority; // Higher priority comes first
    });
    setClientScript(response.script);
    setClientBannedScript(response.banned);
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          borderStyle: "solid",
          height: "100%",
          borderWidth: 1,
          borderColor: COLORSCHEMEA.THEMEFONT,
          borderRadius: 5,
          flex: 1,
        }}
      >
        <div
          style={{
            height: 50,
            display: "flex",
            alignItems: "center",
            paddingLeft: 15,
            justifyContent: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: "bold" }}>Edit Scripts</div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>
              {clientData?.username}
            </div>
          </div>
          <div>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <InputText
                style={{ width: 140 }}
                type="search"
                value={value || ""}
                onChange={(e) => onGlobalFilterChange(e)}
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div className="card">
          <DataTable
            removableSort
            stripedRows
            showGridlines
            scrollHeight={height - 250}
            scrollable
            filters={filters}
            value={clientScript}
            dataKey="symbol"
            size="small"
            loading={loading}
            onFilter={(e) => setFilters(e.filters)}
            globalFilterFields={["name"]}
            rowsPerPageOptions={[15, 30, 50, 100]}
            paginator
            rows={30}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            <Column field="name" sortable header="Name" />

            <Column
              sortable
              field="ban_id"
              style={{ gap: 10 }}
              header="Status"
              body={(rowData) => (
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {rowData?.ban_id > 0 ? (
                    <CreateClientButton background={COLORSCHEMEA.ORANGECOLOR}>
                      Banned
                    </CreateClientButton>
                  ) : (
                    <CreateClientButton background={COLORSCHEMEA.GREENCOLOR}>
                      Active
                    </CreateClientButton>
                  )}
                </div>
              )}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}
