import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import { MultiSelect } from "primereact/multiselect";
import UpdateOnlineStatus from "../Common/UpdateOnlineStatus";
import Alerts from "./Alerts";
import OnlineUsers from "./OnlineUsers";
import RightViewTopComp from "./HomeComponents/RightViewTopComp";
import { apiCall } from "../../Backend/Backend";
import { IoMdSettings } from "react-icons/io";

export default function RightView() {
  const [width, height] = useWindowSize();
  const [countUsers, setCountUsers] = React.useState(0);
  const multiSelectRef = React.useRef(null); // Ref for MultiSelect
  const filterIconRef = React.useRef(null);
  const scrollHieght = height - 320;
  const notificationTypes = [
    { name: "Survelliance" },
    { name: "Boundary" },
    { name: "Liquidated" },
    { name: "Pending" },
  ];

  const [selectedCities, setSelectedCities] = React.useState(null);
  const [alertsData, setAlertsData] = React.useState([]);
  const [filteredAlertsData, setFilteredAlertsData] = React.useState([]);

  React.useEffect(() => {
    if (filterIconRef.current) {
      console.log("filterIconRef is set", filterIconRef.current);
    } else {
      console.log("filterIconRef is null");
    }
  }, []);

  const header1 = (a) => {
    return (
      <div
        style={{
          fontWeight: "bold",
          background: COLORSCHEMEA.THEMEFONT,
          color: "white",
          height: 50,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }} onClick={a.onClick}>
          Alerts
        </div>
        <div style={{ width: 40, alignSelf: "center" }}>
          <div
            className="card flex justify-content-center"
            style={{ position: "relative" }}
          >
            {/* <CiFilter
              size={24}
              style={{ cursor: "pointer" }}
              // ref={filterIconRef} // Attach ref directly to the icon
              onClick={() => {
                // Ensure ref is defined before accessing it
                if (filterIconRef.current) {
                  const iconPosition =
                    // filterIconRef.current.getBoundingClientRect();
                    multiSelectRef.current.show(); // Show MultiSelect dropdown
                  // Adjust the dropdown's position based on the icon's position
                  const dropdown = multiSelectRef.current?.overlayRef.current;

                  if (dropdown) {
                    dropdown.style.top = `${iconPosition.bottom}px`;
                    dropdown.style.left = `${iconPosition.left}px`;
                  }
                } else {
                  console.log("filterIconRef is null");
                }
              }}
            /> */}
            <div
              style={{
                height: "100%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoMdSettings
                size={24}
                style={{
                  cursor: "pointer",
                  // position: "absolute",
                }}
              />
            </div>
            <MultiSelect
              ref={multiSelectRef}
              value={selectedCities}
              onChange={(e) => setSelectedCities(e.value)}
              options={notificationTypes}
              optionLabel="name"
              placeholder="Select Filter"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
              style={{ marginLeft: -10, width: 30, height: 30, opacity: 0 }}
              // style={{ visibility: "hidden", position: "absolute" }} // Hide the MultiSelect element
            />
          </div>
        </div>
      </div>
    );
  };

  const header2 = (a) => {
    return (
      <div
        onClick={a.onClick}
        style={{
          fontWeight: "bold",
          background: COLORSCHEMEA.THEMEFONT,
          color: "white",
          height: 50,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {"Online Users (" + countUsers + ")"}
      </div>
    );
  };

  const [onlineUsersData, setOnlineUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    load_online_users();
  }, []);

  const load_online_users = async () => {
    const response = await apiCall("/load_online_users");
    setLoading(false);
    setOnlineUsersData(response?.online_users);
    setCountUsers(response?.online_users.length);
  };

  React.useEffect(() => {
    load_notification();
  }, []);

  const load_notification = async () => {
    const response = await apiCall("/load_notification");
    setLoading(false);
    setAlertsData(response?.notification);
  };

  // Filter alerts based on selectedCities
  React.useEffect(() => {
    if (!selectedCities || selectedCities.length === 0) {
      setFilteredAlertsData(alertsData);
    } else {
      const filtered = alertsData.filter((alert) =>
        selectedCities.some(
          (city) => city.name === alert.notification_type // Assuming alert.type matches the selected city name
        )
      );
      setFilteredAlertsData(filtered);
    }
  }, [alertsData, selectedCities]);

  return (
    <>
      <UpdateOnlineStatus update={() => load_online_users()} />
      <div style={{ width: 300, display: "flex", flexDirection: "column" }}>
        <RightViewTopComp />
        <div style={{ flex: 1 }}>
          <TabView style={{ height: 55, background: COLORSCHEMEA.THEMEFONT }}>
            <TabPanel
              headerTemplate={header1}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                padding: 0,
              }}
            >
              <Alerts
                load_notification={() => load_notification()}
                notificationList={filteredAlertsData}
                notificationTypes={notificationTypes}
                scrollHieght={scrollHieght}
              />
            </TabPanel>
            <TabPanel
              headerTemplate={header2}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <OnlineUsers
                onlineUsersData={onlineUsersData}
                scrollHieght={scrollHieght}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}
