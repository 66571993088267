import React from "react";

export default function TableHeader() {
  return (
    <div
      style={{
        height: 40,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        backgroundColor: "#f5f5fc",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {"MASTER"}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {"CLIENT"}
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        SYMBOL
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        BUYRATE
      </div>
      {/* <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        SELLRATE
      </div> */}
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        QTY
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        VOLUME
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        LIVERATE
      </div>
      <div
        style={{
          flex: 1.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        M2M
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        BROKERAGE
      </div>
      <div
        style={{
          flex: 1.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        P/L
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          fontSize: 12,
          flexDirection: "column",
        }}
      >
        SQ.<div>OFF</div>
      </div>
    </div>
  );
}
