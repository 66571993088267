import React from "react";
import OnlineCard from "./OnlineCard";
import { apiCall } from "../../Backend/Backend";

export default function OnlineUsers({ scrollHieght, onlineUsersData }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        width: "100%",
        height: scrollHieght,
      }}
    >
      {onlineUsersData &&
        onlineUsersData.map((i, t) => (
          <OnlineCard key={i?.id} type={i?.type} username={i?.username} />
        ))}
    </div>
  );
}
