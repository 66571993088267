import { Avatar } from "primereact/avatar";
import React from "react";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../Common/NumberFormatIn";
import moment from "moment";

export default function UsersCard({ selected, passData, onClick }) {
  return (
    <div
      style={{
        height: 70,
        display: "flex",
        alignItems: "center",
        paddingRight: 10,
        background: selected ? "rgba(0,0,0,0.1" : "unset",
      }}
      onClick={onClick}
    >
      <div style={{ width: 50 }}>
        <Avatar
          label={
            passData?.username ? passData.username.charAt(0).toUpperCase() : ""
          }
          style={{ backgroundColor: COLORSCHEMEA.THEMEFONT, color: "#ffffff" }}
          shape="circle"
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{passData?.username}</div>
        <div style={{ fontSize: 12 }}>
          {passData?.name} {passData?.alias && " | " + passData?.alias}
        </div>
        <div
          style={{
            fontSize: 12,
            color: COLORSCHEMEA.THEMEGRAY,
            fontWeight: "bold",
          }}
        >
          {passData?.last_active_status == "" ? (
            <span style={{ color: COLORSCHEMEA.THEMEGRAY }}> Offline</span>
          ) : parseFloat(passData?.last_active_status) === 0 ? (
            <span style={{ color: COLORSCHEMEA.GREENCOLOR }}> Online</span>
          ) : passData?.last_active_status != null ? (
            moment(passData?.last_active_status).fromNow()
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 12,
            color: COLORSCHEMEA.THEMEGRAY,
          }}
        >
          Margin
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 14,
            color: COLORSCHEMEA.THEMEGRAY,
          }}
        >
          <NumberFormatIn value={parseFloat(passData?.margin).toFixed(0)} />
        </div>
      </div>
    </div>
  );
}
