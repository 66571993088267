import React from "react";
import UsersCard from "./UsersCard";
import { useWindowSize } from "@react-hook/window-size";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export default function UsersList({
  goToAdd,
  goToSummary,
  goToOverview,
  clientsData,
  selectedClient,
  onChange,
}) {
  const [width, height] = useWindowSize();

  const scrollheight = height - 110;

  return (
    <div
      style={{
        minWidth: 300,
        borderRightStyle: "solid",
        borderRightWidth: 0.5,
      }}
    >
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          borderColor: "lightgray",
        }}
      >
        <div style={{ flex: 1 }}>
          <InputText
            id="input"
            placeholder="SEARCH USERNAME"
            onChange={onChange}
            style={{ width: "90%" }}
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            style={{ height: 35, width: 35 }}
            icon="pi pi-plus"
            rounded
            severity="success"
            aria-label="Search"
            onClick={goToAdd}
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            style={{ height: 35, width: 35 }}
            icon="pi pi-list"
            rounded
            severity="warning"
            aria-label="Notification"
            onClick={goToSummary}
          />
        </div>
      </div>
      <div style={{ height: scrollheight, overflowY: "scroll" }}>
        {clientsData &&
          clientsData?.map((i, t) => (
            <UsersCard
              onClick={() => goToOverview(i)}
              selected={selectedClient?.id == i.id}
              passData={i}
              key={i.id}
            />
          ))}
      </div>
    </div>
  );
}
