import React from "react";
import { NumericFormat } from "react-number-format";

export default function NumberFormatIn(props) {
  return (
    <NumericFormat
      value={
        props.fixed
          ? parseFloat(props.value).toFixed(props.fixed)
          : parseFloat(props.value)
      }
      displayType={"text"}
      thousandSeparator={true}
      thousandsGroupStyle="lakh"
      color={props.color}
    />
  );
}
