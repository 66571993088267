import React from "react";
import { useSidebar } from "../Sidebar/SidebarContext";

import TopbarMost from "../Common/TopbarMost";
import Marquee from "./Marquee";
import LiveTrade from "./LiveTrade/LiveTrade";
import { useWindowSize } from "@react-hook/window-size";

export default function LeftView() {
  const { toggleSidebar } = useSidebar();
  const [width, height] = useWindowSize();
  const scrollHieght = height - 10;

  return (
    <div
      style={{
        flex: 1,
        borderRightStyle: "solid",
        borderRightWidth: 0.3,
      }}
    >
      <TopbarMost
        name={"LIVE TRADES "}
        subTitle={localStorage.getItem("server").toUpperCase()}
      />

      <Marquee />
      <LiveTrade />
    </div>
  );
}
