import React from "react";
import styled, { keyframes } from "styled-components";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import PropTypes from "prop-types";

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const rotate = keyframes`
  from {
   transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const colorChange = keyframes`
  0% {
    border-top-color: #00a389; // Green
  }
  50% {
    border-top-color: #e63946; // Red
  }
  100% {
    border-top-color: #00a389; // Green
  }
`;

const LoadingRing = styled.div`
  position: absolute;
  width: ${(props) => (props.small ? "4em" : "5em")};
  height: ${(props) => (props.small ? "4em" : "5em")};
  border: 3px solid transparent;
  border-top: 3px solid #00a389;
  border-radius: 50%;
  background-color: white;
  animation: ${rotate} 1s linear infinite,
    ${colorChange} 2s ease-in-out infinite;
`;

const LoadingContainer = styled.div`
  position: relative;
  bottom: ${(props) => (props.position === "bottom" ? "0" : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => {
    if (props.position === "center") return "3em 0.5em";
    return props.small ? "1.5em 0.5em" : "2em 0.5em";
  }};
  animation: ${(props) => (props.withPulse ? pulse : "none")} 2s infinite
    ease-in-out;
  overflow: hidden;
  min-height: ${(props) => (props.small ? "2em" : "3em")};
  box-sizing: border-box;
  margin: ${(props) => (props.position === "center" ? "1em 0" : "0")};
`;

const LoadingText = styled.div`
  color: ${COLORSCHEMEA.THEMEFONT};
  font-size: 0.8em;
  font-weight: 500;
  opacity: 0.8;
  animation: fadeInOut 1.5s infinite;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
  }
`;

const CandleStickContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.3em;
  height: ${(props) => (props.small ? "1em" : "2em")};
`;

const growCandle = keyframes`
  0% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.3);
  }
`;

const CandleStick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: bottom;
  animation: ${growCandle} 1.5s infinite;
  animation-delay: ${(props) => props.delay}ms;
  height: 100%;
`;

const Wick = styled.div`
  width: 0.1em;
  height: 0.2em;
  background-color: ${COLORSCHEMEA.THEMEFONT};
`;

const Body = styled.div`
  width: 0.3em;
  height: ${(props) => props.height}%;
  background-color: ${(props) =>
    props.isUp ? "#00a389" : "#e63946"}; // Darker colors
  position: relative;
  transition: background-color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      // Reduced white overlay
      rgba(255, 255, 255, 0)
    );
  }
`;

const LoadingComponent = ({
  small = false,
  showText = false,
  showRing = false,
  position = "top",
  withPulse = false,
}) => {
  const candleHeights = [50, 75, 100, 65, 90];
  const directions = [true, false, true, false, true];

  return (
    <LoadingContainer position={position} withPulse={withPulse} small={small}>
      {showRing && <LoadingRing small={small} />}
      <CandleStickContainer small={small}>
        {candleHeights.map((height, index) => (
          <CandleStick key={index} delay={index * 200}>
            <Wick />
            <Body height={height} isUp={directions[index]} />
            <Wick />
          </CandleStick>
        ))}
      </CandleStickContainer>
      {showText && <LoadingText>Loading...</LoadingText>}
    </LoadingContainer>
  );
};

LoadingComponent.propTypes = {
  small: PropTypes.bool,
  showText: PropTypes.bool,
  showRing: PropTypes.bool,
  position: PropTypes.oneOf(["top", "center", "bottom"]),
  withPulse: PropTypes.bool,
};

export default LoadingComponent;
