import * as ExcelJS from "exceljs";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import TopbarMost from "../../Common/TopbarMost";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import NumberFormatIn from "../../Common/NumberFormatIn";

const php = new Php();
const height = window.innerHeight;

export default function Summary() {
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [summary, setSummary] = React.useState([]);
  const [summary2, setSummary2] = React.useState([]);
  const { addNotification } = useNotification();
  const [globalFilter, setGlobalFilter] = React.useState(null);

  React.useEffect(() => {
    load_brokers_summary();
  }, []);

  const load_brokers_summary = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_brokers_summary(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSummary(r.brokers_summary);
          setSummary2(r.brokers_summary);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const Searchclient = (e) => {
    if (e.length > 1) {
      var data = summary2.filter((x) =>
        x.username.toLowerCase().includes(e.toLowerCase())
      );
      setSummary(data);
    } else {
      setSummary(summary2);
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = ["name", "username", "brokerage_amt", "sharing"];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Client",
      "Username",
      "Brokerage Amt",
      "Sharing",
    ];

    const headerRow = worksheet
      .addRow([
        "CLIENTS SUMMARY (S)" +
          localStorage.getItem("server").toUpperCase() +
          " (M)" +
          localStorage.getItem("mastersUsername")?.toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = summary.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("sharing");
    const usernameIndex = colsToExport.indexOf("username"); // Add this line

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const plValue = row.getCell(colIndex + 1).value; // "pl" column value
      const usernameValue = row.getCell(usernameIndex + 1).value; // "username" column value

      // Format for "profit_loss" column
      if (plValue !== null && rowNumber > 4) {
        const plColor =
          plValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(colIndex + 1).font = {
          color: { argb: plColor },
          bold: true, // Make the font bold
        };

        // Convert "profit_loss" to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 4 ? Number(plValue) : plValue;
        row.getCell(colIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "username" column
      if (usernameValue !== null && rowNumber > 4) {
        row.getCell(usernameIndex + 1).font = {
          color: { argb: NewTheme.MainColorExcel }, // Set the font color for the "username" column
          bold: true, // Make the font bold
        };
      }
    });

    var fileName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (M)" +
      localStorage.getItem("mastersUsername")?.toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const cols = [
    { dataKey: "name", title: "Name" },
    { dataKey: "username", title: "Username" },
    { dataKey: "brokerage_amt", title: "Return Brokerage" },
    { dataKey: "sharing", title: "Sharing" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.setFontSize(16);
    doc.text("BROKERS SUMMARY", 15, 15);

    doc.setFontSize(12);
    doc.text(`Server: ${localStorage.getItem("server").toUpperCase()}`, 15, 25);

    doc.setFontSize(12);
    doc.text(
      `Master: ${localStorage.getItem("mastersUsername").toUpperCase()}`,
      55,
      25
    );

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable({
      columns: cols,
      body: summary,
      startY: tableStartY,
      didDrawCell: (data) => {
        if (data.column.dataKey === "sharing") {
          const cellText = data.cell.text;

          if (!isNaN(cellText)) {
            const numericValue = parseFloat(cellText);

            doc.setTextColor(numericValue > 0 ? "green" : "red");
            doc.setFont("helvetica", "bold");

            const roundedValue = numericValue.toFixed(0);

            // Format the value as needed and update the cell text
            const formattedValue = `${roundedValue}`;
            doc.text(formattedValue, data.cell.x + 1, data.cell.y + 5);
          }
        } else if (data.column.dataKey === "brokerage_amt") {
          // Handle formatting for the "Brokerage Amount" column if needed
        }
      },
    });

    var pdfName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (M)" +
      localStorage.getItem("mastersUsername")?.toUpperCase();

    doc.save(pdfName + ".pdf");
  };

  const value = filters["global"] ? filters["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };
  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <InputText
          style={{ height: 25 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      {/* <InputText
        placeholder="SEARCH"
        onChange={(e) => Searchclient(e.target.value)}
        style={{ height: 30 }}
      /> */}
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          // severity="warning"
          style={{
            height: 25,
            fontSize: 10,
            padding: 10,
            color: NewTheme.orangecolor,
            backgroundColor: "#fff",
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          // severity="primary"
          style={{
            height: 25,
            // width: 30,
            fontSize: 12,
            padding: 10,
            color: NewTheme.greencolor,
            backgroundColor: "#fff",
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <div className="card" style={{ backgroundColor: "#ffffff" }}>
      <TopbarMost
        height={30}
        onlyHeader
        cmp={filterbar}
        name={"BROKERS SUMMARY"}
      />
      <DataTable
        removableSort
        stripedRows
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        showGridlines
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        scrollHeight={height - 200}
        scrollable
        paginator
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        // filters={filters}
        value={summary}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="name"
          header="Name"
          sortable
          filter
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="username"
          header="Username"
          sortable
          filter
        ></Column>

        <Column
          style={{
            width: "20%",
          }}
          sortable
          filter
          showFilterMenu={false}
          field="brokerage_amt"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.brokerage_amt >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn
                value={parseFloat(rowData.brokerage_amt).toFixed(0)}
              />
            </span>
          )}
          header="Return Brokerage"
        ></Column>

        <Column
          style={{
            width: "20%",
          }}
          sortable
          showFilterMenu={false}
          field="sharing"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.sharing >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(rowData.sharing).toFixed(0)} />
            </span>
          )}
          header="Sharing P/L"
        ></Column>
        <Column
          style={{
            width: "20%",
          }}
          sortable
          showFilterMenu={false}
          field="sharing"
          body={(rowData) => (
            <span
              style={{
                color:
                  parseFloat(rowData.brokerage_amt) +
                    parseFloat(rowData.sharing) >=
                  0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn
                value={
                  parseFloat(rowData.brokerage_amt) +
                  parseFloat(rowData.sharing)
                }
              />
            </span>
          )}
          header="Difference"
        ></Column>
      </DataTable>
    </div>
  );
}
